import { FeaturesEvent } from '../../SpecialFeatureOptins/SpecialFeatureOptinsEvent';

import { consentsSchema } from './ConsentsSchema';

type Props = {
  features: { [key: string]: boolean };
};

export const consentsToEvent = ({ features }: Props): FeaturesEvent => {
  const event: FeaturesEvent = {
    schema: consentsSchema.ref,
    data: {
      features,
    },
  };

  return event;
};
