'use client';
import { useEffect } from 'react';

import { hasSetCookiesAtom } from '@tracking/Atoms/HasSetCookies.atom';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtom } from 'jotai';
import Script from 'next/script';

import { generateConsentTrackingData } from './ConsentTracking';

type Props = {
  areAdsEnabled: boolean;
};

export const GptScript = ({ areAdsEnabled }: Props) => {
  const [hasSetCookies, setHasSetCookies] = useAtom(hasSetCookiesAtom);

  useEffect(() => {
    const callback = (tcData: any) => {
      if (!tcData) return;

      if (tcData.tcString) setHasSetCookies(true);

      if (hasSetCookies && tcData.eventStatus === 'useractioncomplete') {
        const { consentEvent } = generateConsentTrackingData({ tcData });
        trackCustomEvent({ event: consentEvent.event!, context: consentEvent.context });
      }
    };

    window?.__tcfapi?.('addEventListener', 2, callback);

    return () => {
      window?.__tcfapi?.('removeEventListener', 2, callback);
    };
  }, [hasSetCookies]);

  if (!hasSetCookies || !areAdsEnabled) return null;

  return (
    <Script
      id="gpt-script"
      type="text/javascript"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      async
    />
  );
};

GptScript.displayName = 'GptScript';
