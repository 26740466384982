import Cookies from 'js-cookie';

import { UserContextEvent } from '../Schemas/UserContext/UserContextEvent';

export const updateLoginStatus = (
  context: UserContextEvent[] | undefined,
  event: UserContextEvent,
): void => {
  const isLoggedin = !!Cookies.get('authToken');

  // Update context array
  context?.map((contextEvent: UserContextEvent) => {
    if (contextEvent.schema.includes('user_context')) {
      contextEvent.data.logged_in = isLoggedin;
    }
  });

  // Update single event
  if (event.schema.includes('user_context')) {
    event.data.logged_in = isLoggedin;
  }
};
