'use client';

import { Tenant } from '@core/Entities/App/Tenants.entity';
import { hasSetCookiesAtom } from '@tracking/Atoms/HasSetCookies.atom';
import { useAtomValue } from 'jotai';
import Script from 'next/script';

import { GTM_TAG_MAP } from './GtmTagMap';

type Props = {
  tenant?: Tenant;
};

export const GtmScript = ({ tenant }: Props) => {
  const hasSetCookies = useAtomValue(hasSetCookiesAtom);

  const tag = tenant ? GTM_TAG_MAP[tenant] : GTM_TAG_MAP.heycar;

  if (!hasSetCookies) return null;

  return (
    <>
      <Script
        id="gtm-data-layer-script"
        type="text/javascript"
      >{`window.dataLayer = window.dataLayer || [];`}</Script>

      <Script id="gtm-script" type="text/javascript" strategy="lazyOnload">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${tag}');`}
      </Script>
    </>
  );
};

GtmScript.displayName = 'GtmScript';
