import pino from 'pino';

// Create a custom destination stream
const customDestination = {
  write(chunk) {
    try {
      // Parse the JSON log object
      const logObj = JSON.parse(chunk);

      // Remove 'time' field
      delete logObj.time;

      // Ensure 'level' is uppercase string
      const levelValue = logObj.level;
      const levelLabel = typeof levelValue === 'string' ? levelValue.toUpperCase() : levelValue;
      logObj.level = levelLabel;

      // Convert log object back to JSON string
      const logStr = JSON.stringify(logObj);

      // Output the log line
      process.stdout.write(`${logStr}\n`);
    } catch (err) {
      // If parsing fails, write the raw chunk
      process.stdout.write(chunk);
    }
  },
};

// Initialize the logger with custom destination
export const Log = pino(
  {
    base: null,
    timestamp: false, // Disable the default timestamp
    formatters: {
      level(label) {
        // Map level to uppercase
        return { level: label.toUpperCase() };
      },
    },
  },
  customDestination,
);

// Only override console methods on the server
if (typeof window === 'undefined' && process.env.NODE_ENV !== 'development') {
  const logCreator =
    logLevel =>
    (...args) => {
      // Flatten arguments
      const flatArgs = args.flat(Infinity);

      // Separate arguments into errors, objects, and others
      const errorArgs = flatArgs.filter(arg => arg instanceof Error);
      const objectArgs = flatArgs.filter(
        arg => typeof arg === 'object' && arg !== null && !(arg instanceof Error),
      );
      const otherArgs = flatArgs.filter(arg => typeof arg !== 'object' || arg === null);

      // Log Error objects with stack traces
      errorArgs.forEach(error => {
        Log[logLevel]({ err: error });
      });

      // If there is a single object argument and no other arguments, log the object directly
      if (objectArgs.length === 1 && otherArgs.length === 0 && errorArgs.length === 0) {
        Log[logLevel](objectArgs[0]);
        return;
      }

      // Combine other arguments into a message string
      const messageParts = otherArgs.map(arg => String(arg));

      // Merge object arguments into additionalData
      const additionalData = {};
      objectArgs.forEach(obj => {
        Object.assign(additionalData, obj);
      });

      const message = messageParts.join(' ');

      if (Object.keys(additionalData).length > 0) {
        if (message) {
          // Log with additional data and message
          Log[logLevel](additionalData, message);
        } else {
          // Log with additional data only
          Log[logLevel](additionalData);
        }
      } else if (message) {
        // Log with message only
        Log[logLevel]({ message });
      }
    };

  global.console.debug = logCreator('debug');
  global.console.log = logCreator('info');
  global.console.info = logCreator('info');
  global.console.warn = logCreator('warn');
  global.console.error = logCreator('error');
}
