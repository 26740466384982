import { Tenant } from '@core/Entities/App/Tenants.entity';

export const generateSnowplowAppId = ({ app }: { app?: Tenant | 'native' }) => {
  const suffix: { [key: string]: string } = {
    staging: '_stg',
    development: '_dev',
    production: '',
  };

  return `${app || 'i15'}${
    suffix?.[process.env.NEXT_PUBLIC_APP_ENV || 'development'] ?? suffix.development
  }`;
};
